import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";


import FacebookIcon from "../../../assets/contact/facebook.svg";
import TwitterIcons from "../../../assets/contact/twitter.svg";
import DribbleIcon from "../../../assets/contact/dribble.svg";


const contactSocial = () => (
  <Row>
    <Col xs={12}>
      <Row center="xs">
        <Col xs={12} lg={2} style={{display: 'flex', justifyContent: 'center'}} className="contact__social">
          <img src={FacebookIcon} style={{margin: 0, marginRight: 16}} alt="facebook" />
          <div>NM Services et Distributions</div>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default contactSocial;
