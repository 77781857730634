import React from "react";
// SCSS
import "./teamInfo.scss";

const about = () => (
  <div className="team__info flex-center">
    <div>
      <h4 className="font20 weight800">Savoir un peu plus</h4>
      <p className="font16 weight500">
      Nous réalisons
sur l'en-
semble du territoire ivoirien, des chantiers de nettoyage de vitres et de nettoyage de façade. L'entretien et le nettoyage pour les profes-sionnels de l'immobilier et du bâtiment, constituent un des cours de métiers de notre activité de cordiste.
Le lavage haute pression réalisé par nos cordistes permet de nettoyer une façade sans endommager ni l'enduit ni la peinture
      </p>
    </div>
  </div>
);

export default about;
