import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import Person01 from "../../assets/about/person01.png";
import Person02 from "../../assets/about/person02.png";
import ReactPlayer from "react-player";
import video1 from "../../assets/about/video1.mp4";
import video2 from "../../assets/about/video2.mp4";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="À PROPOS DE NOUS." />
      <p className="font16">
      NM SERVICES & DISTRIBUTIONS est une entreprise spécialisée dans le Service du bio-nettoyage et dans les travaux en hauteur. Créée depuis
Novembre 2021 par MIle Nina Melissa Assoukpou, elle fournit aux entreprises et aux particuliers des services d'entretien et de nettoyage de bureau, fin de chantier et tous types de travaux en hauteur.
Nina Melissa Assoukpou est diplômée d'un Bac +2 en GESTION COMMERCIALE, qu'elle a ensuite complété par une année de Licence en COMMERCE
INTERNATIONAL ET MARKETING.
      </p>
      <Row style={{marginTop: 64}}>
        <Col md={12} lg={4}>
          <ReactPlayer controls width={'100%'} url={video1} />
        </Col>
        <Col md={12} lg={4}>
        <ReactPlayer controls width={'100%'} url={video2} />
        </Col>
        <Col md={12} lg={4}>
          <TeamInfo />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
